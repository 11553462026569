<template>
  <div class="m-pg-sub">
    <page-cover code="11" layer="rgba(0,0,0,.3)">
      <!-- <h2 class="m-page-title">{{$t('page.detail.name')}}</h2>
      <el-breadcrumb class="m-breadcrumb m-box-shadow" separator="/">
        <el-breadcrumb-item :to="{ path: '/' }">{{$t('page.home.name')}}</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/news' }">{{$t('page.news.name')}}</el-breadcrumb-item>
        <el-breadcrumb-item>{{$t('page.detail.name')}}</el-breadcrumb-item>
      </el-breadcrumb> -->
    </page-cover>
    <div class="m-pg-sub_main m-w1200">
      <div class="m-art" v-loading="loading">
        <iframe
          class="frame"
          ref="newframe"
          v-if="iframeUrl"
          :src="iframeUrl"
          @load="setIframeHeight">
        </iframe>
      </div>
    </div>
  </div>
</template>

<script>
import setting from '@/setting'
export default {
  components: {
  },
  data() {
    return {
      loading: true,
      iframeUrl: ''
    }
  },
  methods: {
    setIframeHeight(){
      this.loading = false
    }
  },
  mounted() {
    this.iframeUrl = setting.baseUrl.default + '/' + this.$route.query.detailUrl
    setTimeout(() => {
      this.setIframeHeight()
    }, 1000);
  }
}
</script>

<style lang="less" scoped>
  .m-pg-sub_main {
    margin: 30px auto;
    background-color: #fff;
  }
  .frame {
    height: 1000px;
    width: 100%;
    border: none;
  }
  .m-art {
    &_title {
      margin-bottom: 30px;
      text-align: center;
      font-weight: normal;
    }
    &_content {
      .img {
        margin-bottom: 20px;
        width: 100%;
      }
      p {
        line-height: 26px;
        margin-bottom: 20px;
        text-indent: 2em;
      }
    }
  }
</style>
